import BaseModel from './BaseModel'
import moment from 'moment'

class LoanRepayment extends BaseModel {
  isOverdue () {
    return !this.isPaid() && moment(this.get('payment_date'), 'YYYY-MM-DD') < moment().startOf('day')
  }

  isPaid () {
    return this.get('is_paid') === 1
  }

  amount () {
    return Number(this.get('amount'))
  }

  outstandingCapital () {
    return this.get('capital_out')
  }

  paymentDate () {
    return moment(this.get('payment_date'))
  }

  daysOverdue () {
    return moment().diff(this.paymentDate(), 'days')
  }

  discount () {
    return parseFloat(this.get('discount'))
  }
}

export default LoanRepayment
