import { isArray } from 'lodash'

export default ({ Vue }) => {
  Vue.prototype.$dispatch = async function (actions, returnData = false) {
    if (!isArray(actions)) {
      actions = [actions]
    }

    if (typeof actions[0] === 'string') {
      actions = actions.map(action => {
        return {
          action,
          params: {},
          message: 'Loading...'
        }
      })
    }

    try {
      for (var i = 0; i < actions.length; i++) {
        this.$q.loading.show({
          delay: 400,
          message: actions[i].message
        })
        var data = await this.$store.dispatch(actions[i].action, actions[i].params)
        if (actions[i].successMessage) {
          this.$q.notify({
            icon: 'done',
            color: 'green-5',
            textColor: 'white',
            message: actions[i].successMessage
          })
        }
      }
      this.loading = false
      this.$q.loading.hide()
      return returnData ? data : true
    } catch (e) {
      this.$q.notify({
        color: 'red-5',
        message: e.response.status === 500 ? "It's not you, it's us! There was a problem with the request." : e.response.data,
        timeout: 6000,
        textColor: 'white',
        icon: 'warning'
      })
      this.$q.loading.hide()

      return false
    }
  }
}
