import BaseModel from './BaseModel'
import capitalize from '../utils/capitalize'
import { arrayUniqueByKey } from '../utils/unique'
import CustomerPhone from './CustomerPhone'
import CustomerEmail from './CustomerEmail'

class Customer extends BaseModel {
  fullName () {
    return this.get('title') + ' ' + this.get('first_name') + ' ' + this.get('last_name')
  }

  maritalStatus () {
    return capitalize(this.get('marital_status'))
  }

  dependants () {
    var val = Number(this.get('dependants'))
    return val > 0 ? val : 'None'
  }

  currentAddress () {
    return this.get('addresses').find(a => a.is_current)
  }

  phones () {
    return arrayUniqueByKey(this.get('phones'), 'number').map(phone => new CustomerPhone(phone))
  }

  emails () {
    return arrayUniqueByKey(this.get('emails'), 'address').map(email => new CustomerEmail(email))
  }

  hasIncomeAndExpenditures () {
    return this.get('income_and_expenditures').length > 0
  }
}

export default Customer
