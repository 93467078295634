/*
* In javascript the variables are declared in camelcase but I like use snake case,
* if you want you can rename them as you want and remove the rule in .eslintrc line 35
*/
const apiUrl = env('API_URL') + '/api/'
export default {
  apiUrl,
  token_url: env('API_URL') + '/oauth/token',
  current_user_url: apiUrl + 'user', // you can change it as you want
  endpoints: {
    users_url: apiUrl + 'users'
    // resource_url : api_url + 'resource'
  }
}
