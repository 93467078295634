import BaseModel from './BaseModel'

class CustomerPhone extends BaseModel {
  constructor (props) {
    super(props)
    this._types = {
      1: 'Home',
      2: 'Work',
      3: 'Mobile',
      4: 'Other'
    }
  }

  type () {
    return this._types[this.get('phone_type_id')]
  }

  isMobile () {
    return this.get('phone_type_id') === 3
  }

  number () {
    return this.get('number').replace(/^44/, '(+44)')
  }

  noPrefixNumber () {
    return this.get('number').replace(/^44/, '0')
  }

  isPrimaryMobile () {
    return this.isMobile() && this.get('is_primary_mobile')
  }
}

export default CustomerPhone
