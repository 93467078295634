import BaseModel from './BaseModel'

class CustomerEmail extends BaseModel {
  constructor (props) {
    super(props)
    this._types = {
      1: 'Home',
      2: 'Work',
      3: 'Other'
    }
  }

  type () {
    return this._types[this.get('email_type_id')]
  }

  isDefault () {
    return this.get('is_default')
  }
}

export default CustomerEmail
