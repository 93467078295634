import User from '../../services/user.service'

export const getCurrentUser = async ({ commit, state }, payload) => {
  if (state.currentUser && state.currentUser.isExist()) {
    return state.currentUser
  }

  let user = await User.currentUser()
  commit('users/setCurrentUser', user, {
    root: true
  })
}

export const setCurrentUser = (vuex, user) => {
  const { commit } = vuex
  commit('users/setCurrentUser', user, {
    root: true
  })
}

export const destroyCurrentUser = ({ commit, state }, payload) => {
  commit('users/setCurrentUser', null, {
    root: true
  })

  commit('customer/reset', null, { root: true })
  commit('applications/reset', null, { root: true })
  commit('loans/reset', null, { root: true })
  commit('cards/reset', null, { root: true })
  commit('transactions/reset', null, { root: true })
}
