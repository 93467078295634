import BaseModel from './BaseModel'
import Store from '../store'

class Transaction extends BaseModel {
  constructor (params) {
    super(params)
    this._store = Store()
    this._types = {
      1: 'Regular Repayment',
      2: 'Scheduled Repayment Charge',
      3: 'Direct Charge',
      4: 'Pre Payment Verification',
      5: 'Card Registration',
      6: 'Application Declined',
      7: 'Transaction Reversal',
      8: 'Chargeback',
      9: 'Direct Bank Deposit',
      10: 'Refund',
      11: 'Over Payment',
      12: 'Credit'
    }
  }

  type () {
    return this._types[this.get('transaction_type_id')]
  }

  card () {
    return this._store.state.cards.cards.find(card => card.id() === Number(this.get('card_id')))
  }
}

export default Transaction
