import OAuth from 'src/oauth'
import axios from 'axios'
const http = axios.create()
const auth = new OAuth()

export default async function (to, from, next) {
  if (to.params.token) {
    auth.logout()
    await http.get(env('API_URL') + '/sign-in/' + to.params.token)
      .then(({ data }) => {
        auth.storeSession(data)
      })
      .catch(() => {
        next()
      })
    const user = await auth.currentUser()
    if (user) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
}
