import Vue from 'vue'
import Vuex from 'vuex'

import users from './users'
import customer from './customer'
import applications from './applications'
import loans from './loans'
import cards from './cards'
import transactions from './transactions'
import holidays from './holidays'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      users,
      customer,
      applications,
      loans,
      cards,
      transactions,
      holidays
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: false
  })

  return Store
}
