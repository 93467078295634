import { auth, guest, SignIn, notVerified, logout } from '../router/middlewares'

import Api from '../utils/api'

const routes = [
  {
    path: '*',
    component: () => import('layouts/LoginLayout.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('pages/Login'),
        beforeEnter: guest
      },
      {
        name: 'register',
        path: '/register',
        component: () => import('pages/SetPassword'),
        beforeEnter: guest
      },
      {
        name: 'forgot-password',
        path: 'forgot-password',
        component: () => import('pages/Password'),
        beforeEnter: guest
      },
      {
        name: 'set-password',
        path: '/set-password/:token',
        component: () => import('pages/SetPassword'),
        beforeEnter: guest
      },
      {
        name: 'reset',
        path: 'reset',
        component: () => import('pages/ContinueToLogin'),
        beforeEnter: guest
      },
      {
        name: 'registering',
        path: 'registering/:token',
        component: () => import('pages/ContinueToLogin'),
        beforeEnter: guest
      },
      {
        name: 'verify',
        path: 'verify',
        component: () => import('pages/Verify'),
        beforeEnter: notVerified
      },
      {
        name: 'email-verified',
        path: 'email-verified/:token',
        component: () => import('pages/EmailVerified'),
        async beforeEnter (to, from, next) {
          try {
            await Api().get('api/verified-user/' + to.params.token)
            this.Store.dispatch('users/destroyCurrentUser')
            this.Store.dispatch('users/getCurrentUser')
          } catch (e) {
            next('/')
          }

          next()
        }
      },
      {
        name: 'sign-in',
        path: 'sign-in/:token',
        component: () => import('pages/Login'),
        beforeEnter: SignIn
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MyLayout.vue'),
    children: [
      {
        name: 'my-profile',
        path: '/my-profile',
        component: () => import('pages/Customer'),
        beforeEnter: auth
      },
      {
        name: 'loans',
        path: '/loans',
        component: () => import('pages/Loans'),
        beforeEnter: auth
      },
      {
        name: 'applications',
        path: '/applications',
        component: () => import('pages/Applications'),
        beforeEnter: auth
      },
      {
        name: 'cards',
        path: '/cards',
        component: () => import('pages/CreditCards'),
        beforeEnter: auth
      },
      {
        name: 'dashboard',
        path: '/',
        component: () => import('pages/Dashboard'),
        beforeEnter: auth
      },
      {
        name: 'transactions',
        path: '/transactions',
        component: () => import('pages/Transactions'),
        beforeEnter: auth
      },
      {
        name: 'logout',
        path: '/logout',
        beforeEnter: logout
      },
      {
        name: 'income-and-exp',
        path: 'income-and-exp/:token',
        component: () => import('pages/IncomeAndExpPage'),
        beforeEnter: auth
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      {
        name: 'loan-agreement',
        path: '/loan-agreement/:loanRef',
        component: () => import('pages/LoanAgreement'),
        beforeEnter: auth
      }
    ]
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    name: '404',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
