import Api from '../../utils/api'

export async function getCards ({ state, commit }, fresh = false) {
  if (state.cards && !fresh) {
    return state.cards
  }
  var { data } = await Api().get('api/cards')
  commit('cards/setCards', data, {
    root: true
  })
}

export async function addCard ({ state, commit }, params) {
  var p = JSON.parse(JSON.stringify(params))
  p.card_type_id = params.card_type_id.value
  p.expiry = params.expiry.format('YYYY-MM-DD')
  await Api().post('api/add-card', p)
}

export async function setDefault ({ state, commit }, cardId) {
  commit('cards/setDefault', cardId, {
    root: true
  })
  await Api().post(`api/default-card/${cardId}`)
}

export async function fixCard ({ state, commit }, params) {
  let { data } = await Api().post('api/fix-card', params)
  commit('cards/fixCard', data)
}

export async function deleteCard ({ state, commit }, cardId) {
  await Api().post(`api/delete-card/${cardId}`)
  commit('cards/deleteCard', cardId, {
    root: true
  })
}
