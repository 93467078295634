import moment from 'moment'

import BaseModel from './BaseModel'

class PaymentPlanRepayment extends BaseModel {
  amount () {
    return Number(this.get('amount'))
  }

  date () {
    return moment(this.get('payment_date'))
  }
}

export default PaymentPlanRepayment
