import Api from '../../utils/api'

export async function getApplications ({ state, commit }) {
  if (state.applications) {
    return state.applications
  }

  var { data } = await Api().get('api/applications')
  commit('applications/setApplications', data, {
    root: true
  })
}

export async function getEdoc ({ state }, edocId) {
  var { data } = await Api().post('api/edoc/' + edocId)

  return data
}

export async function sendEdoc ({ state }, edocId) {
  await Api().post('api/send-edoc/' + edocId)
}
