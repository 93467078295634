import OAuth from '../../oauth'
import Store from '../../store'
const auth = new OAuth()

export default async function (to, from, next) {
  const user = auth.currentUser()

  user.then(user => {
    return Store.dispatch('users/setCurrentUser', user)
  }).then(result => {
    next()
  }).catch(error => {
    next()
    throw error
  })
}
