import Config from '../config'
import Http from 'axios'

class User {
  constructor () {
    // this.config.origin = Env('BASE_URL')
    this.config.origin = Config('api.api_url')
    this.config.endpoint = '/users/'
  }

  static currentUser () {
    let response = Http.get(Config('api.current_user_url'))
    return new Promise((resolve, reject) => {
      response
        .then(user => resolve(user.data))
        .catch(error => reject(error))
    })
  }
}

export default User
