import OAuth from '../../oauth'

const auth = new OAuth()

export default async function (to, from, next) {
  try {
    const user = await auth.currentUser()
    if (user) {
      if (user.get('email_verified_at') || user.get('mobile_verified_at')) {
        next('/')
      } else {
        next()
      }
    } else {
      next('/login')
    }
  } catch (e) {
    next()
  }
}
