import BaseModel from './BaseModel'
import moment from 'moment'

class Card extends BaseModel {
  constructor (params) {
    super(params)
    this._types = {
      1: 'Visa Debit',
      3: 'Visa Electron',
      5: 'Switch / Maestro',
      6: 'Mastercard'
    }
    this._typesIcons = {
      1: 'cp_visa',
      3: 'cp_visa_electron',
      5: 'cp_switch',
      6: 'cp_mastercard'
    }
  }

  type () {
    return this._types[this.get('card_type_id')]
  }

  typeIcon () {
    return this._typesIcons[this.get('card_type_id')]
  }

  isDefault () {
    return this.get('is_default')
  }

  lastDigits () {
    return this.get('last_four')
  }

  setDefault (val) {
    this.params.is_default = val

    return this
  }

  isValid () {
    return moment(this.get('expiry')).endOf('month') > moment().startOf('month')
  }

  expiry () {
    return moment(this.get('expiry')).format('MM/YY')
  }

  setParams (params) {
    if (this.id() === params.id) {
      this.params = params
    }
    return this
  }
}

export default Card
