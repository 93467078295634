import auth from './auth'
import web from './web'
import guest from './guest'
import afterEach from './afterEach'
import beforeEach from './beforeEach'
import SignIn from './SignIn'
import notVerified from './notVerified'
import logout from './logout'

export {
  auth,
  web,
  guest,
  beforeEach,
  afterEach,
  SignIn,
  notVerified,
  logout
}
