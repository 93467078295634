import Api from '../../utils/api'

export async function getLoans ({ state, commit }, fresh = false) {
  if (state.loans && !fresh) {
    return state.loans
  }

  var { data } = await Api().get('api/loans')
  commit('loans/setLoans', data, {
    root: true
  })
}

export async function payDueRepayment ({ state, commit }, params) {
  await Api().post('api/pay-due-repayment', params)
}

export async function payPartialRepayment ({ state, commit }, params) {
  await Api().post('api/pay-partial-repayment', params)
}

export async function payEarlySettlement ({ state, commit }, params) {
  await Api().post('api/pay-early-settlement', params)
}

export async function createPayplan ({ state, commit }, params) {
  await Api().post(`api/loan/${params.loan_id}/preset/${params.preset_id}`, { params: params.params })
}

export async function sendRepayments ({ state }) {
  var { data } = await Api().post('api/send-repayments')

  return data
}
