<template>
  <q-icon size="xs" :color="value ? 'green' : 'red'" :name="value ? 'check_circle':'close'"/>
</template>

<script>
export default {
  name: 'BooleanIcon',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style>
</style>
