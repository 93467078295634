<template>
  <div id="q-app">
    <router-view />
  </div>
</template>

<script>
const myIcons = {
  'cp_dashboard': 'img:/statics/cp_dashboard.svg',
  'cp_dashboard_blue': 'img:/statics/cp_dashboard_blue.svg',
  'cp_loans': 'img:/statics/cp_loans.svg',
  'cp_loans_blue': 'img:/statics/cp_loans_blue.svg',
  'cp_card': 'img:/statics/cp_card.svg',
  'cp_orange_card': 'img:/statics/cp_orange_card.svg',
  'cp_dash_card': 'img:/statics/cp_dash_card.svg',
  'cp_tnxs_blue': 'img:/statics/cp_tnxs_blue.svg',
  'cp_dash_card_blue': 'img:/statics/cp_dash_card_blue.svg',
  'cp_tnxs': 'img:/statics/cp_tnxs.svg',
  'cp_tnks_black': 'img:/statics/cp_tnks_black.svg',
  'cp_logout': 'img:/statics/cp_logout.svg',
  'cp_balance': 'img:/statics/cp_balance.svg',
  'cp_phone': 'img:/statics/cp_phone.svg',
  'cp_visa': 'img:/statics/cp_visa.svg',
  'cp_visa_electron': 'img:/statics/cp_visa_electron.svg',
  'cp_switch': 'img:/statics/cp_switch.svg',
  'cp_mastercard': 'img:/statics/cp_mastercard.svg',
  'cp_checked_shield_green': 'img:/statics/cp_checked_shield_green.svg',
  'cp_contract': 'img:/statics/cp_contract.svg',
  'cp_date_closed': 'img:/statics/cp_date_closed.svg',
  'cp_loan_amount': 'img:/statics/cp_loan_amount.svg',
  'cp_checked_shield_grey': 'img:/statics/cp_checked_shield_grey.svg',
  'cp_visa_card': 'img:/statics/cp_visa_card.svg',
  'cp_master_card': 'img:/statics/cp_master_card.svg',
  'cp_coins': 'img:/statics/cp_coins.svg',
  'cp_mb_logo': 'img:/statics/cp_mb_logo.svg',
  'cp_missile': 'img:/statics/cp_missile.svg',
  'cp_new_card': 'img:/statics/cp_new_card.svg',
  'cp_desktop_dashboard': 'img:/statics/cp_desktop_dashboard.svg',
  'cp_paper': 'img:/statics/cp_paper.svg'
}
export default {
  name: 'App',
  async created () {
    if (this.$oauth.isAuthenticated() && !this.$store.state.users.currentUser) {
      await this.$store.dispatch('users/getCurrentUser')
    }

    if (this.$oauth.isAuthenticated()) {
      this.$store.dispatch('holidays/getHolidays')
    }

    this.$q.iconMapFn = (iconName) => {
      const icon = myIcons[iconName]
      if (icon !== void 0) {
        return { icon: icon }
      }
    }
  }
}
</script>

<style>
.q-field {
  width:100%;
}
 .q-table tbody tr:hover {
  background: white;
 }

 .clickable-table tbody tr:hover {
   background: rgba(0,0,0,0.03);
   cursor: pointer;
 }
</style>
