/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/


import Vue from 'vue'

import {Quasar,QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QBtnGroup,QIcon,QList,QItem,QItemSection,QItemLabel,QCard,QCardSection,QCardActions,QSeparator,QTable,QTh,QTr,QTd,QMarkupTable,QTabs,QTab,QTabPanel,QTabPanels,QField,QDialog,QInput,QSelect,QCheckbox,QRadio,QSpace,QTooltip,QDate,QTime,QPopupProxy,QForm,Ripple,ClosePopup,Notify,LocalStorage,Meta,Loading,Platform} from 'quasar'


Vue.use(Quasar, { config: {"screen":{"bodyClasses":true}},components: {QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QBtnGroup,QIcon,QList,QItem,QItemSection,QItemLabel,QCard,QCardSection,QCardActions,QSeparator,QTable,QTh,QTr,QTd,QMarkupTable,QTabs,QTab,QTabPanel,QTabPanels,QField,QDialog,QInput,QSelect,QCheckbox,QRadio,QSpace,QTooltip,QDate,QTime,QPopupProxy,QForm},directives: {Ripple,ClosePopup},plugins: {Notify,LocalStorage,Meta,Loading,Platform} })
