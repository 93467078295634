import Api from '../../utils/api'

export async function getTransactions ({ state, commit }, fresh = false) {
  if (state.transactions && !fresh) {
    return state.transactions
  }
  var { data } = await Api().get('api/transactions')
  commit('transactions/setTransactions', data, {
    root: true
  })
}
