import BaseModel from './BaseModel'
import EDocument from './EDocument'

class Application extends BaseModel {
  status () {
    switch (this.get('application_status_id')) {
      case -2:
      case -1:
        return 'Declined'
      case 0:
        return 'Pending Decision'
      case 1:
        return 'Pending Approval'
      case 2:
        return 'Approved'
      case 3:
        return 'Funded'
      case 4:
        return 'Awaiting Response'
      case 5:
        return 'Awaiting Work Verification'
      case 6:
        return 'About to be funded'
      case 7:
        return 'Ready for approval'
      default:
        return 'Unknown'
    }
  }

  isApproved () {
    return this.status() === 'Approved' && !this.isSigned()
  }

  isSigned () {
    return this.edocs().filter(edoc => edoc.isSigned()).length > 0
  }

  edoc () {
    return this.edocs()[0]
  }

  edocs () {
    return this.get('edocs').map(edoc => new EDocument(edoc))
  }
}

export default Application
