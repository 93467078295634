import Api from '../../utils/api'

export async function getCustomer ({ state, commit }, fresh = false) {
  if (state.customer && !fresh) {
    return state.customer
  }

  var { data } = await Api().get('api/customer')
  commit('customer/setCustomer', data, {
    root: true
  })
}

export async function setDefaultEmail ({ state, commit }, email) {
  commit('customer/setDefaultEmail', email.id(), {
    root: true
  })
  await Api().post(`api/default-email/${email.id()}`, {
    email: email.get('address')
  })
}

export async function setDefaultPhone ({ state, commit }, phone) {
  commit('customer/setDefaultPhone', phone.id(), {
    root: true
  })
  await Api().post(`api/default-phone/${phone.id()}`, {
    mobile: phone.noPrefixNumber()
  })
}

export async function addEmail ({ state, commit }, params) {
  var p = JSON.parse(JSON.stringify(params))
  p.type = params.type.value
  var { data } = await Api().post(`api/add-email`, p)

  commit('customer/addEmail', data, {
    root: true
  })
}

export async function sendVerificationEmail ({ state }, address) {
  await Api().post('api/send-email-verification', { email: address })
}

export async function addPhone ({ state, commit }, params) {
  var p = JSON.parse(JSON.stringify(params))
  p.type = params.type.value
  p.phone = p.phone.replace('+', '')
  var { data } = await Api().post(`api/add-phone`, p)

  commit('customer/addPhone', data, {
    root: true
  })
}

export async function sendMobileVerification ({ state }, phone) {
  await Api().post('api/send-mobile-verification/' + phone.id())
}

export async function sendPrimaryMobileVerification ({ state }) {
  await Api().post('api/send-primary-mobile-verification')
}

export async function verifyMobile ({ state }, params) {
  await Api().post('api/verify-mobile/' + params.phone.id(), { code: params.code })
}

export async function verifyPrimaryMobile ({ state }, params) {
  var { data } = await Api().post('api/verify-primary-mobile', { code: params.code })

  return data
}

// export async function deleteEmail ({ state, commit }, emailId) {
//   await Api().delete(`api/email/${emailId}`)
//
//   commit('customer/deleteEmail', emailId, {
//     root: true
//   })
// }

// export async function deletePhone ({ state, commit }, phoneId) {
//   await Api().delete(`api/phone/${phoneId}`)
//
//   commit('customer/deletePhone', phoneId, {
//     root: true
//   })
// }

export async function requestCallback ({ state }, params) {
  await Api().post(`api/callback`, { params: params })
}

export async function incomeAndExp ({ state }, params) {
  await Api().post(`api/income-and-exp`, { params: params })
}

export async function searchAddressByPostcode ({ state }, params) {
  var { data } = await Api().get(`api/search-address`, { params: params })

  return data
}

export async function updateCurrentAddress ({ state, commit }, params) {
  var { data } = await Api().post(`api/update-address`, params)
  commit('customer/updateAddress', data, {
    root: true
  })
  return data
}

export async function sendSar ({ state }) {
  var { data } = await Api().post('api/send-sar')

  return data
}
