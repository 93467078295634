import moment from 'moment'

import BaseModel from './BaseModel'
import LoanRepayment from './LoanRepayment'
import PaymentPlan from './PaymentPlan'

class Loan extends BaseModel {
  constructor (params) {
    super(params)
    this._statuses = {
      1: 'Regular',
      2: 'Payment Plan',
      3: 'Frozen',
      4: 'Dead',
      5: 'Default',
      6: 'Money Claim',
      7: 'Fraud',
      8: 'Deceased Customer',
      9: 'Modified',
      10: 'DMP',
      11: 'IVA',
      12: 'AOE - Requested',
      13: 'AOE - Suspended',
      14: 'AOE - Full Order',
      15: 'Delinquent',
      16: 'Death Row',
      17: 'Debt Collection',
      18: 'Early Settlement'
    }
  }

  repayments () {
    let repayments = []
    const promiseToPay = this.get('active_promise_to_pay')
    const loanRepayments = this.get('repayments')
    if (promiseToPay) {
      const paidRepayments = loanRepayments.filter(repayment => +repayment.is_paid === 1)
      repayments = [...paidRepayments, ...promiseToPay.repayments]
    } else {
      repayments = loanRepayments
    }
    return repayments.map(repayment => new LoanRepayment(repayment))
  }

  paidRepayments () {
    return this.repayments().filter(repayment => repayment.isPaid())
  }

  unpaidRepayments () {
    return this.repayments().filter(repayment => !repayment.isPaid())
  }

  missedPayments () {
    return this.unpaidRepayments().filter(repayment => repayment.isOverdue())
  }

  isOverdue () {
    return this.missedPayments().length > 0
  }

  isPayable () {
    return !['Dead', 'Deceased Customer'].includes(this.status())
  }

  paidAmount () {
    return this.paidRepayments().reduce((acc, repayment) => acc + repayment.amount(), 0)
  }

  unpaidAmount () {
    return this.unpaidRepayments().reduce((acc, repayment) => acc + repayment.amount(), 0)
  }

  discount () {
    return this.get('discounts').reduce((acc, cur) => acc - Number(cur.amount), 0)
  }
  unallocatedDiscount () {
    let repaymentsDiscount = this.repayments().reduce((acc, repayment) => acc + repayment.discount(), 0)
    let unallocatedDiscount = repaymentsDiscount + this.discount()

    return unallocatedDiscount < 0 ? unallocatedDiscount : 0
  }

  charges () {
    return this.get('charges').reduce((acc, cur) => acc + Number(cur.cost), 0)
  }

  currentBalance () {
    return this.unpaidAmount() + this.accruedInterest() + this.unallocatedDiscount()
  }

  accruedInterest () {
    return parseFloat(this.get('loan_attributes').unallocated_accrued_int)
    // if(frozen_date is not null and frozen_date < date_add(l.loan_date, INTERVAL seq DAY),
    //   0.00,
    //   cast((l.amount - ifnull((sum(sum(lrs.capital)) over
    //   (partition by l.id  order by  l.id, seq asc)), 0) + ifnull(lrs.capital, 0)) * 0.007 as decimal(8, 6)))
  }

  total () {
    return this.repayments().reduce((acc, repayment) => acc + repayment.amount(), 0)
  }

  amount () {
    return Number(this.get('amount'))
  }

  paidPercent () {
    return Math.round((this.paidAmount() / this.total()) * 100)
  }

  paidCount () {
    return this.paidRepayments().length
  }

  paidDiffForRepayment (i) {
    var total = this.contractualPlan().repayments().slice(0, i + 1).reduce((acc, cur) => acc + cur.amount(), 0)

    // subtract discount from last repayment
    if (i === this.contractualPlan().repayments().length - 1) {
      total -= this.discount()
    }
    return total - this.paidAmount()
  }

  overdueData () {
    var ODRepayments = this.contractualPlan().overdueRepayments()
    var ODRepaymentIndex = ODRepayments.findIndex((r, i) => this.paidDiffForRepayment(i) > 0)

    if (ODRepaymentIndex < 0) {
      return false
    }

    var ODRepayment = ODRepayments[ODRepaymentIndex]

    var days = moment().diff(ODRepayment.date(), 'days')
    var amount = this.contractualPlan().overdueRepayments().reduce((acc, val) => acc + val.amount(), 0) - this.paidAmount() + this.charges() - this.discount()

    return {
      days,
      amount
    }
  }

  contractualRepaymentPaidStatus (i) {
    var diff = this.paidDiffForRepayment(i)
    var contractualAmount = this.contractualPlan().repayments()[i].amount()

    // paid
    if (diff <= 0) return true

    // not paid
    if (diff >= contractualAmount) return false

    // partly paid percentage
    return (contractualAmount - diff) / contractualAmount
  }

  earlySettlementAmount () {
    return this.get('loan_attributes').pay_off_now
  }

  nextRepayment () {
    return this.overdueRepayments().sort((a, b) => moment(a.get('created_at')).diff(moment(b.get('created_at'))))[0]
  }

  accruesInterest () {
    return this.overdueRepayments().length && !(this.isFrozen() || this.exceededCap() || this.inBreathingSpace())
  }

  isFrozen () {
    return this.get('frozen_date') && moment(this.get('frozen_date')) < moment()
  }

  exceededCap () {
    return parseFloat(this.get('total_interest')) >= parseFloat(this.get('cap'))
  }

  overdueRepayments () {
    return this.unpaidRepayments().filter(repayment => repayment.isOverdue())
  }

  firstRepayment () {
    return this.unpaidRepayments().sort((a, b) => moment(a.get('created_at')).diff(moment(b.get('created_at'))))[0]
  }

  isOpen () {
    return this.get('closed') === 0
  }

  isDefault () {
    return this.get('loan_status_id') === 5
  }
  inBreathingSpace () {
    return this.get('loan_status_id') === 19
  }

  inLegalStatus () {
    return [6, 7, 12, 13, 14, 15, 16, 17].includes(this.get('loan_status_id'))
  }

  settledEarly () {
    return this.get('loan_status_id') === 18 && !this.isOpen()
  }

  onContractualPlan () {
    return this.paymentPlans().length === 1
  }

  contractualPlan () {
    return this.paymentPlans()[0]
  }

  paymentPlans () {
    return this.get('payment_plans').map(plan => new PaymentPlan(plan))
  }

  status () {
    var statusId = this.get('loan_status_id')

    switch (true) {
      case (statusId === 1 && !this.isOverdue()):
        return 'Payment Up-to-date'
      case (statusId === 1 && this.isOverdue()):
        return 'Overdue'
      case (this.inLegalStatus()):
        return 'Legal Proceedings'
      case (this.isDefault()):
        return 'Default'
      case (this.settledEarly()):
        return 'Early Settlement'
      case (!this.onContractualPlan()):
        return 'Payment Arrangement'
      default:
        return this._statuses[statusId]
    }
  }

  duration (repaymentIndex) {
    // var repayment = this.repayments()[repaymentIndex]
    // var dateKey = repayment.isPaid() ? 'date_paid' : 'payment_date'

    var dateKey = 'payment_date'
    var repaymentDate = moment(this.repayments()[repaymentIndex].get(dateKey))
    var prevRepayment = repaymentIndex > 0 ? this.repayments()[repaymentIndex - 1] : null
    var prevRepaymentDate

    if (prevRepayment) {
      prevRepaymentDate = prevRepayment.get('payment_date')

      // prevRepayment.isPaid() ? prevRepayment.get('date_paid') :  prevRepayment.get('payment_date')

      prevRepaymentDate = moment(prevRepaymentDate)
    } else {
      prevRepaymentDate = moment(this.get('loan_date'))
    }

    return repaymentDate.diff(prevRepaymentDate, 'days')
  }

  lastRepayment () {
    return this.repayments().sort((a, b) => moment(b.get('payment_date')).diff(moment(a.get('payment_date'))))[0]
  }

  dailyInterest () {
    let interestRate = 0.007
    let outstandingCapital = this.unpaidRepayments().reduce((acc, repayment) => acc + Number(repayment.get('capital')), 0)

    return outstandingCapital * interestRate
  }

  availablePresets () {
    return this.get('available_presets')
  }
}

export default Loan
