class BaseModel {
  constructor (params) {
    this.params = params
  }

  get (param) {
    if (!this.params.hasOwnProperty(param)) {
      throw new Error(`Parameter ${param} does not exist on model ${this.constructor.name}`)
    }

    return this.params[param]
  }

  id () {
    return Number(this.get('id'))
  }

  isExist () {
    return !!this.params
  }

  set (key, value) {
    this.params[key] = value
  }
}

export default BaseModel
