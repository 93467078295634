import moment from 'moment'

import BaseModel from './BaseModel'
import PaymentPlanRepayment from './PaymentPlanRepayment'

class PaymentPlan extends BaseModel {
  repayments () {
    return this.get('payments').map(repayment => new PaymentPlanRepayment(repayment))
  }

  overdueRepayments () {
    return this.repayments().filter(repayment => repayment.date() <= moment())
  }

  total () {
    return this.repayments().reduce((acc, curVal) => acc + curVal.amount(), 0)
  }
}

export default PaymentPlan
