import Api from '../../utils/api'

export async function getHolidays ({ state, commit }) {
  if (state.holidays) {
    return state.holidays
  }

  var { data } = await Api().get('api/holidays')
  commit('holidays/setHolidays', data, {
    root: true
  })
}
