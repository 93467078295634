import Customer from '../../models/Customer'

export const setCustomer = (state, payload) => {
  state.customer = new Customer(payload)
}

export const setDefaultEmail = (state, emailId) => {
  state.customer.params.emails = state.customer.params.emails.map(email => {
    email.is_default = email.id === emailId
    return email
  })
}

export const setDefaultPhone = (state, phoneId) => {
  state.customer.params.phones = state.customer.params.phones.map(phone => {
    if (phoneId === phone.id) {
      phone.confirmed = true
    }
    phone.is_primary_mobile = phoneId === phone.id

    return phone
  })
}

export const addEmail = (state, email) => {
  state.customer.params.emails.push(email)
}

export const deleteEmail = (state, emailId) => {
  state.customer.params.emails = state.customer.params.emails.filter(email => email.id !== emailId)
}

export const deletePhone = (state, phoneId) => {
  state.customer.params.phones = state.customer.params.phones.filter(phone => phone.id !== phoneId)
}

export const addPhone = (state, phone) => {
  state.customer.params.phones.push(phone)
}

export const updateAddress = (state, address) => {
  var addresses = state.customer.params.addresses
  addresses.push(address)

  state.customer.params.addresses = addresses.map(function (a) {
    a.is_current = address.id === a.id

    return a
  })
}

export const reset = (state) => {
  state.customer = null
}
