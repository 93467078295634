import OAuth from '../../oauth'

const auth = new OAuth()

export default async function (to, from, next) {
  try {
    const user = await auth.currentUser()
    // console.log('auth middleware', user)

    if (user) {
      if (user.get('email_verified_at') || user.get('mobile_verified_at')) {
        next()
      } else {
        next('/verify' + window.location.search)
      }
    } else {
      next('/login' + window.location.search)
    }
  } catch (e) {
    // console.log('Error in auth middleware', e)
    next()
  }
}
