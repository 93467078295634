import OAuth from '../../oauth'

const auth = new OAuth()

export default async function (to, from, next) {
  try {
    const user = await auth.currentUser()
    if (user) {
      await auth.logout()
    }
    next('/login')
  } catch (error) {
    next('/login')
  }
}
