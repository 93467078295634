import moment from 'moment'

export default ({ Vue }) => {
  Vue.filter('date', (val, format = 'DD-MM-YYYY') => {
    return val ? moment(val).format(format) : '-'
  })
  Vue.filter('datetime', (val) => {
    return val ? moment(val).format('lll') : '-'
  })

  Vue.filter('currency', (val, isInt = false) => {
    var decPlaces = 2
    var decSep = '.'
    var thouSep = ','
    var sign = ''
    var number = Math.abs(Number(val)) || 0
    var i = String(parseInt(number))
    var j = (i.length) > 3 ? i.length % 3 : 0

    return '£' + sign +
        (j ? i.substr(0, j) + thouSep : '') +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
        (!isInt ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : '')
  })

  /**
   * Formats a phone number.
   * Example: 123-456-7890 => (123) 456-7890
   *
   * @param {String} phone
   * @return {Void}
   */
  Vue.filter('phone', function (phone) {
    return phone.replace(/[^0-9]/g, '')
      .replace(/(44)(\d{3})(\d{4})/, '+($1) $2-$3')
  })
}
