import Card from '../../models/Card'

export const setCards = (state, payload) => {
  state.cards = payload.map(card => new Card(card))
}

export const setDefault = (state, cardId) => {
  state.cards = state.cards.map(card => {
    return card.setDefault(card.id() === cardId)
  })
}

export const reset = (state) => {
  state.cards = null
}

export const fixCard = (state, payload) => {
  state.cards = state.cards.map(card => {
    return card.setParams(payload)
  })
}

export const deleteCard = (state, cardId) => {
  state.cards = state.cards.filter(card => card.id() !== Number(cardId))
}
