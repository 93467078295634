import axios from 'axios'
import { LocalStorage } from 'quasar'

export default () => {
  // console.log(env('API_URL'))
  return axios.create({
    baseURL: env('API_URL'),
    headers: {
      'Authorization': 'Bearer ' + LocalStorage.getItem('access_token')
    }
  })
}
